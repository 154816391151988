import { render, staticRenderFns } from "./DashboardFtxMatchaErc.vue?vue&type=template&id=833bc69c&"
import script from "./DashboardFtxMatchaErc.vue?vue&type=script&lang=js&"
export * from "./DashboardFtxMatchaErc.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports